const iPhoneVersion = ()=>{
	var iHeight = window.screen.height; var iWidth = window.screen.width;
	var version = parseInt(window.navigator.userAgent.split("OS")[1].split("like")[0], 10);
	if (iWidth === 414 && iHeight === 896) {
		if(version<13) return "XR, XS Max"
		return "XR, XS Max, 11, 11 Pro Max"
	}
	else if (iWidth === 430 && iHeight === 932) { return "14 Pro Max"; }
	else if (iWidth === 393 && iHeight === 852) { return "14 Pro"; }
	else if (iWidth === 428 && iHeight === 926) { return "12 Pro Max, 13 Pro Max, 14 Plus"; }
	else if (iWidth === 390 && iHeight === 844) { return "12, 12 Pro, 13, 13 Pro, 14"; }
	else if (iWidth === 375 && iHeight === 812) {
		if(version<13) return "X, XS"
		if(version<14) return "X, XS, 11 Pro"
		return "X, XS, 11 Pro, 12 Mini, 13 mini";
	}
	else if (iWidth === 320 && iHeight === 480) { return "4"; }
	else if (iWidth === 375 && iHeight === 667) { 
		if(version>15) { return "8"; }
		if(version>12) { return "6s, 7, 8"; }
		if(version<11) { return "6, 6s, 7"; }
		return "6, 6s, 7, 8";
	}
	else if (iWidth === 414 && iHeight === 736) { 
		if(version>15) { return "8+"; }
		if(version>12) { return "6s+ 7+, 8+"; }
		if(version<11) { return "6+, 6s+, 7+"; }
		return "6+, 6s+ 7+, 8+";
	}
	else if (iWidth === 320 && iHeight === 568) { 
		if(version>12) { return "SE"; }
		return "5, 5S, SE"; 
	}
	else if (iHeight <= 480) { return "2, 3, 3GS, 4, 4S"; }
	return '';
}

const iPhoneGeneration = ()=>{
	var iHeight = window.screen.height; var iWidth = window.screen.width;
	var version = parseInt(window.navigator.userAgent.split("OS")[1].split("like")[0], 10);
	
	if (iWidth === 414 && iHeight === 896) {
		if(version<13) return 9 //"XR, XS Max"
		return 11 //"XR, XS Max, 11, 11 Pro Max"
	}
	else if (iWidth === 430 && iHeight === 932) { return 14 }
	else if (iWidth === 393 && iHeight === 852) { return 14 }
	else if (iWidth === 428 && iHeight === 926) { return 12 } //"12 Pro Max"; }
	else if (iWidth === 390 && iHeight === 844) { return 12 } //"12, 12 Pro"; }
	else if (iWidth === 375 && iHeight === 812) {
		if(version<13) return 10 //"X, XS"
		if(version<14) return 10 //"X, XS, 11 Pro"
		return 10 //"X, XS, 11 Pro, 12 Mini";
	}
	else if (iWidth === 320 && iHeight === 480) { return 4 } //"4"; }
	else if (iWidth === 375 && iHeight === 667) { return 7 } //"6, 6s, 7, 8"; }
	else if (iWidth === 414 && iHeight === 736) { return 7 } //"6+, 6s+ 7+, 8+"; }
	else if (iWidth === 320 && iHeight === 568) { return 5 } //"5, 5S, SE"; }
	else if (iHeight <= 480) { return 5 } //"2, 3, 3GS, 4, 4S"; }
	return 13;
}

// stuff about browser/platform detection - lifted directly from webSDK
const device = {
	isWindows: navigator.platform=="Win32",
	isMacOS: navigator.platform=="MacIntel",
	isChromeOS: navigator.userAgent.match("CrOS")?true:false,
	isTrident: (navigator.userAgent.match("Edge/")?true:false),
	isEdge: (navigator.userAgent.match("Edge/")?true:false),
	isOrca: (navigator.userAgent.match("Orca")?true:false)||(navigator.userAgent.match("FB_")?true:false),
	isOutlook: (navigator.userAgent.match("ms-office")?true:false)||(navigator.userAgent.match("Outlook")?true:false),
	isHotmail: (navigator.userAgent.match("hotmail")?true:false),
	isiOS: (navigator.userAgent.match("iPhone|iPod|iPad")?true:false),
	isiPad: (navigator.userAgent.match("iPad")?true:false),
	isiPhone: (navigator.userAgent.match("iPhone|iPod")?true:false),
	isLinux: (navigator.userAgent.match("Linux")?true:false),
	isAndroid: (navigator.userAgent.match("Android")?true:false),
	screenSize: (()=>{return {w:screen.width, h:screen.height};})(),
	isWebSDKViewer: (navigator.userAgent.match("websdk-viewer")?true:false),
}

{
	// extend the device object further
	device.isSamsung = (!device.isTrident&&navigator.userAgent.match("SamsungBrowser/")?true:(!device.isTrident&&navigator.userAgent.match("SamsungInternet/")?true:false)),
	device.isBrave = (navigator.userAgent.match("Brave")?true:false)||window.navigator.brave,
	device.isChrome = (!device.isTrident&&navigator.userAgent.match("Chrome/")?true:false),
	device.isFirefox = (!device.isTrident&&navigator.userAgent.match("Firefox/")?true:false),
	device.isSafari = (!device.isChrome&&navigator.userAgent.match("Safari/")?true:false),
	device.isMobile = (device.isiPhone||device.isAndroid&&navigator.userAgent.match("Mobile")?true:false),
	device.iPhoneGen = device.isiPhone?iPhoneGeneration():-1;

	// check if this is an iphone or ipad pretending to be OSX - until Apple releases a touch-enabled macbook 
	// iPad screen widths
	let widths = [768, 810, 834, 820, 1024, 1112, 1366]
	if(device.isMacOS && widths.indexOf(device.screenSize.w)!=-1 && navigator.maxTouchPoints>=5) {
		device.isMobile = true; device.isMacOS = false; device.isiPad = true; device.isiOS = true;
		if(widths.indexOf(device.screenSize.w)>=4) { device.isiPadPro = true; }
	} else if (device.isMacOS && device.screenSize.w < 1000 && navigator.maxTouchPoints>=5) {
		device.isMobile = true; device.isiPhone = true; device.isMacOS = false; device.isiOS = true; device.iPhoneGen=device.isiPhone?iPhoneGeneration():-1
	} else if (device.isiPad && widths.indexOf(device.screenSize.w)>=3) { device.isiPadPro = true; } else { device.isiPadPro = false; }

	device.support={
		getUserMedia:(navigator.mediaDevices&&navigator.mediaDevices.getUserMedia?true:false),
		asyncFunctions:(function() { eval("var test = (async function() {})"); return ('function'==typeof test?true:false) })(),
		webGL:(function(){
			var canvas = document.createElement("canvas"), gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
			if (gl && gl instanceof WebGLRenderingContext) { return true; } else { return false; }
	  	})(),
	  	webGLInfo:()=>{ const gl = document.createElement("canvas").getContext("webgl"); for (let i in gl) { let val = gl.getParameter(gl[i]); if (val != null) console.log(i, val) } },
	  	highDPI: window.matchMedia("(-webkit-min-device-pixel-ratio: 2)").matches, touch:device.isiOS?true:window.navigator.maxTouchPoints>1?true:false, incognito: undefined
	}
	
	device.summary={
		form:(device.isMobile?"mobile":(device.isAndroid||device.isiPad)?"tablet":"computer"),
		platform:(device.isiOS?"iOS":device.isAndroid?"Android":device.isMacOS?"MacOS":device.isWindows?"Windows":device.isLinux?"Linux":device.isChromeOS?"Chrome OS":"other"),
		version: (()=>{
				if(device.isAndroid) return parseFloat((window.navigator.userAgent.split("Android")[1].split(";")[0]))
				if(device.isiPhone) return window.navigator.userAgent.split("OS")[1].split("like")[0]
				if(device.isChromeOS||device.isChrome) return window.navigator.userAgent.split("Chrome/")[1].split(" ")[0]
		})(),
		model:(device.isiPhone?"iPhone "+iPhoneVersion():device.isiPad?(device.isiPadPro?"iPad Pro":"iPad"):device.isAndroid?(()=>{return window.navigator.userAgent.split(")")[0].split(";").pop().split("Build")[0].trim();})():"Other"),
		browser: (device.isEdge?"Edge":device.isTrident?"IE":device.isFirefox?"Firefox":device.isSamsung?"Samsung Internet":device.isChrome?"Chrome":device.isSafari?"Safari":
		device.isOrca?"Facebook messenger":device.isBrave?"Brave Browser":device.isHotmail?"Hotmail embedded":device.isOutlook?"Outlook embedded":"Other"),
		supportVideo:device.support.getUserMedia?true:false,
		supportWebGL:device.support.webGL?true:false,
		supportTouch: device.support.touch,
		incognito: undefined,
		screenAttributes: { pixelRatio: devicePixelRatio, screenSize: device.screenSize, screenSpace:(()=>{ return {w: window.innerWidth, h: window.innerHeight}; })(), },
		userAgent: navigator.userAgent,
		navigator: { vendor: navigator.vendor, platform: navigator.platform, memory: navigator.deviceMemory, touchPoints: navigator.maxTouchPoints, concurrency: navigator.hardwareConcurrency, }
	}	
}

export default device