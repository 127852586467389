// imports MUST NOT use aliasing
import EventHandler from "./event-handler.js"

const PersistentWebSocket = (()=>{
	
	let messageIndex = 1
	let clientId = (function uuidv4() {
		if('undefined' != typeof window.crypto) {
			// best version, uses the crypto object (if supported)
			return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (
				c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
			).toUpperCase();
		} else {
			// failover, uses Math.random... eeee.
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
				var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8); return v.toString(16);
	  		}).toUpperCase();
		}
	})()
	
	try {
		if(window.sessionStorage) {
			let previousId = window.sessionStorage.getItem("clientId")
			if(previousId) { clientId = previousId } else { window.sessionStorage.setItem("clientId", clientId) }
		}
	} catch(e) {
		console.warn(e)
	}
	
	return function(source="generic", version=0) {
		const ws = {
			socket: null,
			reconnection:{ time: 500, attempts: 1, auto: true, maxAttempts: 4, maxBackoff: 5, timeout:null },
			url: null,
			events: new EventHandler(),
			addEventListener(e,f) { this.events.addEventListener(e, f) },
			removeEventListener(e,f) { this.events.removeEventListener(e, f) },
			on(e,f) { this.events.addEventListener(e,f) },
			off(e,f) { this.events.removeEventListener(e,f) },
			handleEvent(e,d) { this.events.handle(e, d) },
			replay(timeInSeconds=30) {
				const now = new Date().getTime()/1000
				const from = now - timeInSeconds
				this.send({
					"messageType":"replay", 
					"messageSinceTs": from
				})
			},
			sendRaw(json, parse=true) {
				if(ws.socket) {
					if('string'==typeof json) {
						try {
							let j = JSON.parse(json)
						} catch(e) {
							json = { message: json }
						}
					}
					try {
						if(!parse) {
							ws.socket.send(json)
						} else {
							ws.socket.send(JSON.stringify({...json}))
						}
					} catch(e) {
						ws.handleEvent("error", {type:"error", e})
					}
				} else {
					ws.handleEvent("error", "websocket not available")
				}
			},
			deferred: [],
			send(json) {
				if(ws.socket == null || ws.socket && ws.socket.readyState !== 1) { 
					ws.deferred.push(json)
					return
				}
				if(ws.socket) {
					if('string'==typeof json) {
						try {
							let j = JSON.parse(json)
						} catch(e) {
							json = { message: json }
						}
					}
					try {
						ws.socket.send(JSON.stringify({
							...{ // these can be override by JSON
								ttl:0
							},
							...json, 
							...{ // these cannot be overriden
								sourceSystem: source,
								messageVersion: version,
								uuid: clientId,
								// messageId: `${clientId}-${messageIndex++}`,
							}
						}))
					} catch(e) {
						ws.handleEvent("error", {type:"error", e})
					}
				} else {
					ws.handleEvent("error", "websocket not available")
				}
			},
			parseMessage(e) {
				if(e.type != "message") return
				try {
					const payload = JSON.parse(e.data)
					ws.handleEvent("message", { type:"message", data: payload })
				} catch(e) {
					ws.handleEvent("error", {type:"error", e})
				}
			},
			reconnect() {
				ws.socket = null
				if(!ws.reconnection.auto) { return console.debug("socket not auto-reconnecting, disabled") }
				if(ws.reconnection.attempts >= ws.reconnection.maxAttempts) { 
					this.handleEvent("connectionError", "Unable to connect to websocket")
					return console.debug("socket not auto-reconnecting, too many attempts") 
					
				}
				const connectTime = ws.reconnection.time*(2*Math.min(ws.reconnection.attempts, ws.reconnection.maxBackoff))
				console.debug("attempting to reconnect, ", ws.reconnection.attempts, connectTime)
				clearTimeout(ws.reconnection.timeout)
				ws.reconnection.timeout = setTimeout( ws.connect, connectTime )
				ws.reconnection.attempts++
			},
			connect(url, resetAttempts=false) {
				
				if(url) (ws.urlBase = url) && (ws.url = url)
				// if(ws.urlBase) ws.url = ws.urlBase
				// if(ws.reconnection.attempts < 4) {
				// 	ws.url = ws.urlBase+"INTENTIONALLY-BROKEN-WS-CONNECTION"
				// }
				console.warn(ws.url)
				
				if(resetAttempts) ws.reconnection.attempts=1 
				return new Promise((accept, reject)=>{
					if(ws.socket && ws.socket.readyState in [0,1]) { 
						// close the existing websocket
						ws.socket.close()
					}
					if(!ws.socket) {
						console.debug("Connecting WS to", ws.url)
						ws.socket = new WebSocket(ws.url)
						ws.socket.onopen = e=>{ ws.handleEvent("open", e), ws.reconnection.attempts = 1, console.debug("socket connected", new Date().getTime()/1000<<0), accept();
							// process defered messages
							let l = ws.deferred.length
							for(let i=0;i<l;i++) {
								ws.send(ws.deferred.shift())
							}
						}
						ws.socket.onmessage = e=>{ ws.parseMessage(e) }
						ws.socket.onerror = e=>{ ws.handleEvent("error", e) }
						ws.socket.onclose = e=>{ ws.handleEvent("close", e), ws.socket=null, console.debug("socket closed", new Date().getTime()/1000<<0), ws.reconnect() }
					}
				})
			},
			close(json=null) {
				const reconnect = ws.reconnection.auto
				// disable reconnect this was an intentional close
				ws.reconnection.auto = false
				if(json) { ws.send(json) }
				ws.socket.close()
				// re-enable reconnect if it was enabled
				if(reconnect) { setTimeout(()=>{ws.reconnection.auto = true}, 250) }
			}
		}
		return ws
	}
})()

// exports MUST NOT use aliasing
export default PersistentWebSocket