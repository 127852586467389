import EventHandler from "./event-handler.js"
import { api, error } from "./api.js"
import device from "./device.js"


// check for scantek superglobal exists
if(window.scantek) throw new Error("Conflict, window.scantek already exists")

const icons = {
	cross:"<svg height=\"24px\"  width=\"24px\" viewBox=\"0 0 24 24\">  <defs>    </defs>  <g stroke=\"currentColor\" stroke-width=\"[WEIGHT]\" fill=\"currentColor\" stroke-linecap=\"round\">   <path d=\"M 15.59 7 C 13.898 7 12 10.59 12 10.59 L 8.41 7 C 8.41 7 7 7.745 7 8.41 C 7 10.102 10.59 12 10.59 12 L 7 15.59 C 7 15.59 7.745 17 8.41 17 C 10.102 17 12 13.41 12 13.41 L 15.59 17 C 15.59 17 17 16.255 17 15.59 C 17 13.898 13.41 12 13.41 12 L 17 8.41 C 17 8.41 16.255 7 15.59 7 Z\"/>  </g> </svg>",
	arrowLeft: "<svg height=\"24px\"  width=\"24px\" viewBox=\"0 0 24 24\">  <defs>    </defs>  <g stroke=\"currentColor\" stroke-width=\"[WEIGHT]\" fill=\"none\" stroke-linecap=\"round\">   <path d=\"M 14.96 6.06 L 8.89 12.01 L 14.99 18.05\"/>  </g>  <path d=\"M 0 0 L 24 0 L 24 24 L 0 24 L 0 0 Z\" fill=\"none\"/> </svg>",
	ellipsisVertical: "<svg height=\"24px\" viewBox=\"0 0 24 24\" width=\"24px\" fill=\"currentColor\">  <defs>    </defs>  <g stroke=\"currentColor\" stroke-width=\"1\" fill=\"currentColor\" stroke-linecap=\"round\">   <path d=\"M 12 7.46 C 13.1 7.46 13.49 7.1 13.49 6 C 13.49 4.9 13.1 4.49 12 4.49 C 10.9 4.49 10.58 4.9 10.58 6 C 10.58 7.1 10.9 7.46 12 7.46 Z M 12 10.49 C 10.9 10.49 10.58 10.9 10.58 12 C 10.58 13.1 10.9 13.46 12 13.46 C 13.1 13.46 13.49 13.1 13.49 12 C 13.49 10.9 13.1 10.49 12 10.49 Z M 12 16.49 C 10.9 16.49 10.58 16.9 10.58 18 C 10.58 19.1 10.9 19.46 12 19.46 C 13.1 19.46 13.49 19.1 13.49 18 C 13.49 16.9 13.1 16.49 12 16.49 Z\"></path>  </g> </svg>"
}

let scantekSuperGlobal

const noop = ()=>{ console.debug("no-op") }
// suppress debug logging in prod
const isDevHost = window.location.host.includes("scantek.io")||window.location.host.includes("dev.demo")?true:false
const isDev = isDevHost; //true; // (window.localStorage&&window.localStorage.getItem&&window.localStorage.getItem("scantek_dev_mode")==="true")?true:isDevHost?true:false
let __log=noop,__debug=noop,__error=noop,__warn=noop
if("console" in window) {
	if('function' == typeof window.console.log) __log = console.log;
	if('function' == typeof window.console.debug) __debug = console.debug;
	if('function' == typeof window.console.error) __error = console.error;
	if('function' == typeof window.console.warn) __warn = console.warn;
	if(!isDev) __debug = noop
}
__debug("Running scantek-client.js in debug mode")

let viewportHeight = 0;//window.innerHeight||window.clientHeight
const resizeMonitor=()=>{
	if(window.innerHeight!==viewportHeight) {
		viewportHeight = window.innerHeight
		document.documentElement.style.setProperty("--vh", viewportHeight+"px")
		document.documentElement.style.setProperty("--vhm", (viewportHeight-24)+"px")
	}
}
window.addEventListener("resize", resizeMonitor), resizeMonitor();

const frameStyles = {
	hidden: `
		display:none;
	`,
	inline: `
		border: 0;
		width: 100%;
		min-width: 320px;
		margin:0px auto;
		min-height:500px;
		transition: min-height 100ms ease-in-out;
	`,
	fullscreen: `
		position:fixed;
		z-index:1000;
		border: 0;
		background:#fff;
		top:0px;
		left: 0px;
		width: 100vw;
		height: var(--vh) !important;
	`,
	get overlay() {
		return this.fullscreen+`
		top:12px;
		left: 12px;
		width: calc( 100% - 24px);
		height: var(--vhm) !important;
		box-shadow: 0 0 20px rgba(0,0,0,0.25);
		border-radius:24px;
		`
	}
}

const panelStyles = {
	hidden: ``,
	show: `
	
	`
}

const buttonStyle = {
			// display:block;
	get base() {
		return `
			line-height:48px;
			box-sizing: border-box;
			border:0;
			padding:0 8px;
			background: ${api.theme.colours.accentBright};
			color:#fff;
		`
	},
	get flat() {
		return buttonStyle.base+`;
		`
	},
	get round() {
		return buttonStyle.base+`;
			border-radius: 24px;
		`
	},
	get disabled() {
		return `;
			cursor: not-allowed;
			background:gray;
			color: darkgray;
		`
	},
	get close() {
		return `
			position:absolute;
			curosr:pointer;
			background: ${api.theme.colours.accentBright};
			width:48px;
			height:48px;
			line-height:18px;
			border-radius: 24px 0px 4px 0px;
			z-index:1001;
			top:11px;
			left:11px;
			margin:0px;
			padding:0px;
			box-shadow:0 0 10px rgba(0,0,0,0.25);
		`
	}, 
	get settings() {
		return `
			position:fixed;
			bottom:16px;
			right:16px;
			curosr:pointer;
			background: ${api.theme.colours.accentBright};
			width:48px;
			height:48px;
			line-height:18px;
			border-radius: 24px 24px 4px 24px;
			z-index:1001;
			margin:0px;
			padding:0px;
			box-shadow:0 0 10px rgba(0,0,0,0.25);
		`
	}
	
}

let scantekError = noop
let scantekFatalError = noop
let scantekComplete = noop

const processIPC = function processIPC(frame, event) {
	if(!frame) { return }

	switch(event.type) {
		case "resize":
			if(event.height) {
				if(frame.displayMode=="inline") {
					frame.style.minHeight = event.height+"px"
				}
			}	
		break;
		case "modal":
			// on modal trigger, we want to center the iframe in the viewport
			window.scrollTo({
				top: (frame.offsetTop+frame.offsetHeight/2)-window.innerHeight/2,
				behavior: "smooth"
			})
		break;
		case "status":
			switch(event.status) {
				case "launch":
					scantek.handleEvent("status", {
						status: "launch",
						message: "Launching embedded process",
					});
					break;
				case "submitting":
					scantek.handleEvent("submitting", {
						status: "submitting", message: "submitting VOI records for validation.",
					});
					// scantek.handleEvent("status", {
					// 	status: "submitting", message: "submitting VOI records for validation.",
					// });
					break;
				case "notSupported":
					scantek.handleEvent("status", {
						status: "notSupported",
						message: "Local device or browser not supported, facilitating device hand-off.",
					});
					break;
				case "complete":
					scantek.handleEvent("status", {
						status: "complete",
						message: "submission of records complete.",
					});
					scantekComplete()
					break;
				case "error":
				case "notFound":
				case "expired":
					scantekFatalError(event.status)
					break;
				case "offline":
					scantekFatalError(event.status)
					break;
				case "IPC":
					scantek.handleEvent("IPC",  event.message)
					break;
			}
		break;
		case "requestFullscreen": 
			// this should only ever trigger on iOS ...
			// let's pop the iframe into a psudo fullscreen mode
			if(frame.opts.display == "inline") frame.style.cssText = frameStyles.fullscreen
		break;
		case "cancelFullscreen":
			frame.style.cssText = (frame.opts.display == "inline")?frameStyles.inline:frameStyle.overlay
		break;
	}
}

let readyTriggered = false
let readyCallback = null
let messageCallback = null
let ready = false
let complete = false
let socket
let launchButton
let closeButton

const settings = {
	button: null,
	panel: null,
	overrides: {}
}


const scantek = scantekSuperGlobal = {
	get api() {  if(isDev) { return api } return null },
	get theme() { return api.theme },
	get targetTransaction() { return api.config.targetTransaction||null },
	set isReady(v) { ready = true },
	get isReady() { return ready },
	events: new EventHandler(),
	addEventListener(e,f) { 
		this.events.addEventListener(e, f) 
		
		if(e == "ready" && ready == true) this.handleEvent("ready")
		if(e == "complete" && complete == true) this.handleEvent("complete")
	},
	removeEventListener(e,f) { this.events.removeEventListener(e, f) },
	on(e,f) {this.events.addEventListener(e, f) },
	handleEvent(e,d) { this.events.handle(e, d) },
	disable() {
		const b = launchButton
		if(b) {
			b.setAttribute("disabled", "disabled")
			b.style.cursor = "not-allowed"
			b.style.cssText += buttonStyle.disabled
		}
	},
	enable() {
		const b = launchButton
		if(b) {
			b.removeAttribute("disabled")
			b.style.cursor = "not-allowed"
			if(b.opts) {
				switch(b.opts) {
					case "none": 
						// don't set any styling, just let the host page handle it
						b.style.cssText = ""
					break;
					case "flat": 
						// use default scantek style, minus rouding
						b.style.cssText = buttonStyle.flat
					break;
					case "round": 
						// use default scantek style
						b.style.cssText = buttonStyle.round
					default:
					break;
				}
			}
		}
	},
	
	show() {
			
	},
	
	hide() {
		
	},
	
	init: (voiCode, callback)=>{
		return new Promise(async (accept, reject)=>{
			
			// api.webSocket.addEventListener("connectionError", (e)=>{
			// 	scantekError(new error("SCTKE.4.2.0"))
			// })
			
			api.authorize(voiCode).then(()=>{
				__debug("authorized")
				
				scantek.isReady = true
				if('function' == typeof callback) callback(true) 
				scantek.handleEvent("ready")
				accept(true)
				
				if(isDev && 1==0) {
					// attach a settings override button
					const b = settings.button = document.createElement("button")
					b.style.cssText = buttonStyle.settings
					b.innerHTML = icons.ellipsisVertical
					document.body.appendChild(b)
					
					const p = settings.panel = document.createElement("div")
					p.style.cssText = panelStyle.show
					p.innerHTML = "stuff will be here..."
					document.body.appendChild(p)
					
				}
				
			}).catch(e=>{
				reject(e)
				scantek.handleEvent("error", e )
			})
		})
	},
	
	setupVerification: (config={})=>{
		const defaults = {
			domElement: null,
			display: "inline",
			launch: "button",
			style: {...{
				button: "round",
				panels: "round",
			}, ...config.style}
		}
		const opts = {...defaults, ...config }
		let base

		return new Promise(async (accept, reject)=>{ 
			scantekComplete = (e)=>{
				scantek.handleEvent("complete", {
					message: "Identity verification complete",
				})
				accept("Identity verification complete")
			}
			
			scantekError = (e)=>{
				scantek.handleEvent("error", e)
			}
			
			scantekFatalError = (e)=>{
				scantek.handleEvent("error", {
					message: "Unable to continue: "+e
				})
				reject(new error("SCTKE.4.2.0"))
			}

			window.addEventListener("message", (e)=>{
				try {
					// adding type safety for this JSON parse ... thanks Lucas
					if("string" == typeof e.data && e.data.startsWith("JSON:")) {
						processIPC(runner, JSON.parse(e.data.replace("JSON:", "")))
					} else {
						console.log("IPC", e.data)
						processIPC(runner, {type:"status", status:"IPC", message:e.data})
					}
				} catch(err) {
					console.debug("unable to process message", e, err)
				}
			})
			
			if(!scantek.isReady) reject(new error("SCTKE.4.0.1"))


			// lookup the dom element
			if('string' == typeof opts.domElement) {
				const element = document.querySelector(opts.domElement)
				if(!element) return reject(new error("SCTKE.4.1.0"))
				base = opts.base = element
				for(;base.hasChildNodes();)base.removeChild(base.lastChild);
			}
			
			let start, stop, runner, button, frame, started=false
			switch(opts.display) {
				case "overlay":
					
					closeButton = document.createElement("button")
					closeButton.style.cssText = buttonStyle.close
					closeButton.setAttribute("title", "Hide Verification Process")
					closeButton.innerHTML = `${icons.arrowLeft}`
					closeButton.addEventListener("click", ()=>{
						stop();
					})
					// base.appendChild(closeButton)
					
				case "inline":

					frame = document.createElement("iframe")
					frame.setAttribute("allow", "camera; geolocation; fullscreen;")
					let sdkUrl = `${api.urlSdk}${api.voi}?ipc=frame&mode=${opts.display}`
					if(opts.style&&opts.style.panels&&["flat", "round"].includes(opts.style.panels)) {
						sdkUrl+="&style=flat"
					}
					if(opts.preload==true) frame.src = sdkUrl
					frame.opts = opts
					start = ()=>{
						__warn("sdk url", sdkUrl)
						// create an iframe to contain the scantek webSDK and pass in the requisite options
						if(frame.opts.display == "inline") {
							frame.displayMode = "inline"
							frame.style.cssText = frameStyles.inline
						}else {
							frame.displayMode = "overlay"
							frame.style.cssText = frameStyles.overlay
							base.appendChild(closeButton)
						}
						if(opts.preload!==true && !started) frame.src = sdkUrl
						started = true
						runner = frame
					}
					frame.style.cssText = frameStyles.hidden
					scantek.events.handle("status", {
						status: "preloading",
					})
					base.appendChild(frame)
				break;
				case "window":
					
					start = () =>{
						const sdkUrl = `${api.urlSdk}${api.voi}?ipc=window`
						runner = window.open(sdkUrl, "scantek-verification-client")
					} 
					
				break;
			}
			
			
			switch(opts.launch) {
				case "immediate":
					start()
					// runner.onmessage = (e)=>{
					// 	console.log("message received", e)
					// }
				break;
				case "button":
					// TODO create a button to launch the thing!
					launchButton = button = document.createElement("button")
					if(opts.style && opts.style.button) {
						launchButton.opts = opts.style.button||null
					}
					button.innerHTML = opts.launchLabel||"Start Verification"
					button.setAttribute("id", "scantek-start-verification")
					scantek.enable()
					
					button.addEventListener("click", e=>{
						e.preventDefault() // in case we're inside a form element
						if(opts.display!="window") {
							button.remove()
						}
						
						scantek.events.handle("status", {
							status: "launch",
						})
						
						start()
					})
					base.appendChild(button)
				break;
			}
			
			stop=()=>{
				console.log("stop")
				scantek.events.handle("status", { status:"closedByUser" })
				if(closeButton) closeButton.remove()
				if(launchButton) base.appendChild(launchButton)
				if(frame) { frame.style.cssText = frameStyles.hidden }
			}
			
			// TODO: if the code looks right, connect to the api and get an auth token
			if(!api.voiCode) await scantek.init(voiCode).catch(reject)
			// TODO: connect to the WebSocket relay with the auth token
		}).catch(e=>{
			scantek.handleEvent("error", e)
		})
	},
}

window.scantek = scantekSuperGlobal
export default scantek