const EventHandler = (() => {
	const makeEvent = (e, type="synthetic") => {
		let t = !1, i = !1, n = {type: type,message: e,get bubbles() {return !1},get cancelable() {return !1},cancelBubble: !1,get composed() {return !0},get eventPhase() {return 0},get isTrusted() {return !0},get defaultPrevented() {return t},get propagationStopped() {return i},preventDefault: () => {t = !0},stopPropagation: () => {i = !0},}
		if ( "string" == typeof e) { n['message'] = e } else if (null != typeof e) { delete(n.message); for (let t in e) (void 0 !== n[t] && "type" != t) || (n[t] = e[t]) }
		return n
	}
	return function () {
		;(this.handlers = {}),
			(this.handle = (event, data) => {
				const e = makeEvent(data, event)
				if (this.handlers[event] && this.handlers[event].length > 0) {
					this.handlers[event].forEach((f) => {
						if ("function" == typeof f) {
							f(e)
							if(f.opts?.once==true) {
								this.removeEventListener(event, f)
							}
						}
					})
				}
			}),
			(this.addEventListener = (event, func, opts=undefined) => {
				if (!this.handlers[event]) this.handlers[event] = []
				if (this.handlers[event].indexOf(func) > -1) {
					console.warn("listener already registered")
					return
				}
				func.opts = opts
				this.handlers[event].push(func)
			}),
			(this.removeEventListener = (event, func) => {
				if (this.handlers[event] && this.handlers[event].indexOf(func) > -1) {
					console.debug(`removing listener for '${event}'`)
					this.handlers[event].splice(this.handlers[event].indexOf(func), 1)
				}
			})
	}
})()

// exports MUST NOT use aliasing
export default EventHandler
